import Api from "./api";
import { AxiosError, AxiosResponse } from "axios";

export class ProfileApi extends Api {
  private token = "";

  constructor() {
    super();
    this.token = localStorage.getItem("token") || "";
  }

  private getFormData = (object: Record<string, any>) =>
    Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key]);
      return formData;
    }, new FormData());

  public getProfile(): Promise<AxiosResponse> {
    return this.get<ServerResponse>("/profile", {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public editProfile(
    data: Record<string, any>,
    user: string | any = null
  ): Promise<AxiosResponse> {
    if (!user) {
      user = JSON.parse(localStorage.getItem("user") as string);
      user = user.id || "";
    }
    return this.post<ServerResponse>("/profile/update/" + user, this.getFormData(data), {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public editProfileDetail(
    data: Record<string, any>,
    user: string | any = null
  ): Promise<AxiosResponse> {
    if (!user) {
      user = JSON.parse(localStorage.getItem("user") as string);
      user = user.id;
    }
    return this.post<ServerResponse>("/profile/detail/" + user, data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public editProfileLanguage(
    data: Record<string, any>,
    user: string | any = null
  ): Promise<AxiosResponse> {
    if (!user) {
      user = JSON.parse(localStorage.getItem("user") as string);
      user = user.id;
    }
    return this.post<ServerResponse>("/profile/language/" + user, data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public editProfileWork(
    data: Record<string, any>,
    user: string | any = null
  ): Promise<AxiosResponse> {
    if (!user) {
      user = JSON.parse(localStorage.getItem("user") as string);
      user = user.id;
    }
    return this.post<ServerResponse>("/profile/work/" + user, data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public editProfilefamily(
    data: Record<string, any>,
    user: string | any = null
  ): Promise<AxiosResponse> {
    if (!user) {
      user = JSON.parse(localStorage.getItem("user") as string);
      user = user.id;
    }
    return this.post<ServerResponse>("/profile/family/" + user, data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public editProfileStudy(
    data: Record<string, any>,
    user: string | any = null
  ): Promise<AxiosResponse> {
    if (!user) {
      user = JSON.parse(localStorage.getItem("user") as string);
      user = user.id;
    }
    return this.post<ServerResponse>("/profile/study/" + user, data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public changePass(data: Record<string, any>): Promise<AxiosResponse> {
    return this.post<ServerResponse>(
      "/profile/password",
      this.getFormData(data),
      {
        headers: {
          Authorization: "Bearer " + this.token
        }
      }
    );
  }
}
