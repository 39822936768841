












































import { Component, Vue } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import store from "@/store";
import { ProfileApi } from "@/networks/ProfileApi";
import GeneralApi from "@/networks/GeneralApi";
import Country from "@/views/components/inputs/country.vue";
@Component({
  components: {
    Country
  }
})
export default class InfoProfile extends Vue {
  private form: Record<string, unknown> = {
    citizenship: null
  };

  private errors: Record<string, Array<string>> = {};
  private countries = [];
  async mounted() {
    this.form = this.$store.state.user?.user_detail;
    const res = await new GeneralApi().countries({});
    this.countries = res.data.data;
  }
  public async submit(): Promise<void> {
    try {
      const form = this.form;
      Object.keys(this.form).forEach((key) => !form[key] && delete form[key]);
      const res: AxiosResponse = await new ProfileApi().editProfileDetail(
        form,
        this.$route.params.id
      );
      this.errors = {};
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
      this.$emit("next");
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }
}
