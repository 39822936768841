


























































import { Component, Vue } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import store from "@/store";
import { ProfileApi } from "@/networks/ProfileApi";

@Component({})
export default class PasswordProfile extends Vue {
  private passForm: Record<string, unknown> = {};
  private passErrors: Record<string, Array<string>> = {};
  private visible: false | undefined = false;

  public async passSubmit(): Promise<void> {
    try {
      const passForm = this.passForm;
      Object.keys(this.passForm).forEach(
        (key) => !passForm[key] && delete passForm[key]
      );
      const res: AxiosResponse = await new ProfileApi().changePass(passForm);
      this.passErrors = {};
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
      (this.$refs.passForm as Vue & { reset: () => boolean }).reset();
      this.passForm = {};
    } catch (error: any) {
      if (error.response.status == 422) {
        this.passErrors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }
}
