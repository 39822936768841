





















































































































import { Component, Vue } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import store from "@/store";
import { ProfileApi } from "@/networks/ProfileApi";
import Enum from "@/config/enum";
import FileUploader from "@/views/components/inputs/fileUploader.vue";
import GeneralApi from "@/networks/GeneralApi";

@Component({
  components: { FileUploader }
})
export default class StudyProfile extends Vue {
  private types3 = Enum.grades;
  private payloading = false;
  private dialog = false;
  private image = "";

  private form: Array<Record<string, unknown>> = [];
  private errors: Record<string, Array<string>> = {};

  mounted() {
    this.form = this.$store.state.user?.user_study;
  }
  public addTeam(): void {
    const forms = this.form || [];
    forms.push({});
    this.form = forms;
  }

  public deleteTeam(index: number): void {
    const mentors = [...this.form];
    mentors.splice(index, 1);
    this.form = mentors;
  }

  public async submit(): Promise<void> {
    try {
      const form: FormData = new FormData();
      this.form.map((x: any, i: number) => {
        form.append(`study[${i}][id]`, x.id);
        form.append(`study[${i}][grade]`, x.grade || "");
        form.append(`study[${i}][university]`, x.university || "");
        form.append(`study[${i}][average]`, x.average);
        form.append(`study[${i}][study_field]`, x.study_field || "");
        if (x.file && !x.file.id) {
          form.append(`study[${i}][file]`, x.file);
        }
      });
      const res: AxiosResponse = await new ProfileApi().editProfileStudy(
        form,
        this.$route.params.id
      );
      // this.form = res.data.data;
      this.errors = {};
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
      this.$emit("next");
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }

  public async download(item: Record<string, string>): Promise<void> {
    this.payloading = true;
    try {
      const res: AxiosResponse = await new GeneralApi().download(item.id, {
        public: "true"
      });
      if (item.mime.substr(0, 5) == "image") {
        this.dialog = true;
        this.image = res.data.data;
      } else this.forceFileDownload(res, item.original_name);
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    } finally {
      this.payloading = false;
    }
  }

  forceFileDownload(response: AxiosResponse, title: string) {
    console.log(title);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", title);
    document.body.appendChild(link);
    link.click();
  }
}
