


















































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import store from "@/store";
import { ProfileApi } from "@/networks/ProfileApi";

@Component({
  components: {}
})
export default class WorkProfile extends Vue {
  private form: Array<Record<string, unknown>> = [];
  private errors: Record<string, Array<string>> = {};

  mounted() {
    this.form = this.$store.state.user?.user_work;
  }

  public addTeam(): void {
    const forms = this.form || [];
    forms.push({
      price: null,
      type: null,
      explanation: null
    });
    this.form = forms;
  }

  public deleteTeam(index: number): void {
    const mentors = [...this.form];
    mentors.splice(index, 1);
    this.form = mentors;
  }

  public async submit(): Promise<void> {
    try {
      const form: any = this.form;
      const res: AxiosResponse = await new ProfileApi().editProfileWork(
        { work: form },
        this.$route.params.id
      );
      this.form = res.data.data;
      this.errors = {};
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
      this.$emit("next");
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }
}
