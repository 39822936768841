

















import { Vue, Watch, Prop, Component } from "vue-property-decorator";
import GeneralApi from "@/networks/GeneralApi";
import store from "../../../store";
import { AxiosResponse } from "axios";
import { VAutocomplete } from "vuetify/lib";

@Component({
  components: {
    VAutocomplete
  }
})
export default class Province extends Vue {
  @Prop({})
  defaultValue: Array<string> | undefined;
  private provinces: Array<Record<string, unknown>> = [];
  private province_id: Array<string> | undefined = [];
  private loading = false;
  private search = "";

  public async country(q = ""): Promise<void> {
    try {
      const res: AxiosResponse = await new GeneralApi().province({ q });
      this.provinces = res.data.data;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  @Watch("province_id")
  public onPageChanged() {
    this.$emit("change-value", this.province_id);
  }

  private _timerId = 0;
  fetchEntriesDebounced(): void {
    // cancel pending call
    clearTimeout(this._timerId);

    // delay new call 500ms
    this._timerId = window.setTimeout(() => {
      this.country(this.search);
    }, 700);
  }

  @Watch("search")
  public onSearchChanged(val: string, old: string) {
    if (val != old) {
      this.fetchEntriesDebounced();
    }
  }

  @Watch("defaultValue")
  public onDefaultValueChanged(val: any) {
    this.province_id = val;
  }
}
