










































































































































































import PasswordProfile from "@/views/pages/Profile/Password.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import DatePicker from "vue-persian-datetime-picker";
import { AxiosResponse } from "axios";
import store from "@/store";
import { ProfileApi } from "@/networks/ProfileApi";
import VueCropper from "vue-cropperjs";
import moment from "moment-jalaali";
import "cropperjs/dist/cropper.css";
import Office from "@/views/components/inputs/office.vue";
import Enum from "@/config/enum";
import { maxLength, minLength, required } from "vuelidate/lib/validators";

const persianAlpha = (value = "") => !!value.match(/^[\u0600-\u06FF\s]+$/);

@Component({
  components: {
    DatePicker,
    PasswordProfile,
    VueCropper,
    office: Office
  }
})
export default class MainProfile extends Vue {
  private form: Record<string, unknown> = {};
  private errors: Record<string, Array<string>> = {};
  private dialog = false;
  private datePopup = false;
  showDialog = false;
  private today = moment().format("YYYY-MM-DD");
  private baseUrl = process.env.VUE_APP_URL || "https://api.bms-go2tr.com";
  private imgSrc: string | ArrayBuffer | null | undefined = "";
  private cropImg: string | ArrayBuffer | null | undefined = "";
  private roles = localStorage.getItem("roles") || [];
  private types = Enum.military_status;
  private image = {};

  validations() {
    return {
      form: {
        fname: {
          required,
          persianAlpha
        },
        lname: {
          required,
          persianAlpha
        },
        national_code: {
          maxLength: maxLength(10),
          minLength: minLength(10)
        }
      }
    };
  }

  mounted() {
    this.form = this.$store.state.user;
  }
  public async submit(): Promise<void> {
    try {
      const form = this.form;
      Object.keys(this.form).forEach((key) => !form[key] && delete form[key]);
      const res: AxiosResponse = await new ProfileApi().editProfile(form);

      if (res.data.data) {
        localStorage.setItem("user", JSON.stringify(res.data.data));
      }

      this.errors = {};
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
      this.$emit("next");
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }

  public cropImage(): void {
    this.cropImg = (this.$refs.cropper as any).getCroppedCanvas().toDataURL();
    this.form.avatar = this.image; //this.cropImg;
    this.dialog = false;
  }

  PersianDate(date: any) {
    return moment(date).format("jYYYY-jMM-jDD");
  }

  public setImage(): void {
    const file = (this.$refs.avatar as any).files[0];
    this.image = file;
    this.dialog = true;
    if (file.type.indexOf("image/") === -1) {
      alert("Please select an image file");
      return;
    }
    if (typeof FileReader === "function") {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event && event.target) {
          this.imgSrc = event.target.result;
          (this.$refs.cropper as any).replace(event.target?.result);
        }
      };
      reader.readAsDataURL(file);
      alert("برای آپلود عکس گزینه ی ذخیره در پایین صفحه را بزنید");
    } else {
      alert("Sorry, FileReader API not supported");
    }
  }

  // @Watch("$store.state.user")
  // public onDataChanged() {
  //   this.form = this.$store.state.user;
  // }
}
