




























































































import { Component, Vue } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import store from "@/store";
import { ProfileApi } from "@/networks/ProfileApi";
import Enum from "@/config/enum";

@Component({
  components: {}
})
export default class FinancialProfile extends Vue {
  private types = Enum.all_money_i_have;
  private types2 = Enum.monthly_salary;
  private types3 = Enum.type_of_fund;
  private key = 1;

  private form: Record<string, unknown> = {};
  private fund: Array<Record<string, unknown>> = [];

  private errors: Record<string, Array<string>> = {};

  mounted() {
    this.form = this.$store.state.user?.user_detail;
    this.fund = this.$store.state.user?.user_fund;
  }

  public addTeam(): void {
    const funds = this.fund || [];
    funds.push({
      price: null,
      type: null,
      explanation: null
    });
    this.fund = funds;
  }

  public deleteTeam(index: number): void {
    const mentors = [...this.fund];
    mentors.splice(index, 1);
    this.fund = mentors;
  }

  public async submit(): Promise<void> {
    try {
      const form = this.form;
      Object.keys(this.form).forEach((key) => !form[key] && delete form[key]);
      form.fund = this.fund;
      const res: AxiosResponse = await new ProfileApi().editProfileDetail(
        form,
        this.$route.params.id
      );
      this.fund = res.data.data;
      this.errors = {};
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
      this.$emit("next");
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }
}
