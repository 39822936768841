




































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import store from "@/store";
import { ProfileApi } from "@/networks/ProfileApi";
import Enum from "@/config/enum";
import Country from "@/views/components/inputs/country.vue";

@Component({
  components: { Country }
})
export default class MethodProfile extends Vue {
  private types = Enum.MigrationMethods;
  private key = 1;

  private form: Record<string, unknown> = {};

  private errors: Record<string, Array<string>> = {};

  mounted() {
    this.form = this.$store.state.user?.user_detail;
  }

  public async submit(): Promise<void> {
    try {
      const form = this.form;
      Object.keys(this.form).forEach((key) => !form[key] && delete form[key]);
      const res: AxiosResponse = await new ProfileApi().editProfileDetail(form);
      this.errors = {};
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
      this.$emit("next");
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }
}
