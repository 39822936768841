






















































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import store from "@/store";
import { ProfileApi } from "@/networks/ProfileApi";
import MainProfile from "@/views/pages/Profile/Main.vue";
import PasswordProfile from "@/views/pages/Profile/Password.vue";
import ContactProfile from "@/views/pages/Profile/Contact.vue";
import MethodProfile from "@/views/pages/Profile/Method.vue";
import Financial from "@/views/pages/Profile/Financial.vue";
import LanguageProfile from "@/views/pages/Profile/Language.vue";
import StudyProfile from "@/views/pages/Profile/Study.vue";
import WorkProfile from "@/views/pages/Profile/Work.vue";
import InfoProfile from "@/views/pages/Profile/Info.vue";
import FamilyProfile from "@/views/pages/Profile/Family.vue";

@Component({
  components: {
    MainProfile: MainProfile,
    PasswordProfile: PasswordProfile,
    InfoProfile: InfoProfile,
    MethodProfile: MethodProfile,
    FamilyProfile: FamilyProfile,
    WorkProfile: WorkProfile,
    StudyProfile: StudyProfile,
    LanguageProfile: LanguageProfile,
    FinancialProfile: Financial,
    ContactProfile: ContactProfile
  }
})
export default class Profile extends Vue {
  private tab = 0;
  private data: Record<string, unknown> = {};
  successDialog = false;
  mounted() {
    this.getProfile();
  }
  get user() {
    return this.$store.state.user;
  }
  public async getProfile(): Promise<void> {
    try {
      const res: AxiosResponse = await new ProfileApi().getProfile();
      const data = res.data.data;
      console.log(res.data.data);
      data.user_detail = data.user_detail || {};
      store.commit("saveUser", data);
      localStorage.setItem("user", JSON.stringify(data));
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }
  next() {
    if (this.tab == 8) {
      this.tab = 0;
      this.successDialog = true;
      return;
    }
    this.tab++;
  }
}
