

















import { Vue, Watch, Prop, Component } from "vue-property-decorator";
import GeneralApi from "@/networks/GeneralApi";
import store from "../../../store";
import { AxiosResponse } from "axios";
import { VAutocomplete } from "vuetify/lib";

@Component({
  components: {
    VAutocomplete
  }
})
export default class City extends Vue {
  @Prop({ default: 5 })
  defaultValue: string | undefined;
  @Prop()
  province: string | undefined;
  @Prop({ default: "شهر" })
  label: string | undefined;

  private cities: Array<Record<string, unknown>> = [];
  private city_id: string | undefined = "";
  private loading = false;
  private search = "";

  mounted() {
    this.city();
    console.log(this.defaultValue, "city default value", this.label);
    this.city_id = this.defaultValue;
  }

  public async city(q = ""): Promise<void> {
    try {
      const res: AxiosResponse = await new GeneralApi().city({
        q,
        province_id: this.province
      });
      this.cities = res.data.data;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  @Watch("city_id")
  public onPageChanged() {
    this.$emit("change-value", this.city_id);
  }

  @Watch("search")
  public onSearchChanged() {
    this.city(this.search);
  }

  @Watch("defaultValue")
  public onDefaultValueChanged(val: string) {
    this.city_id = val;
  }

  @Watch("province")
  public onProvinceChanged() {
    this.city();
  }
}
